
export const menuItems = [
         
        {
          name: "#",
          label: "Home",
          
        },
        {
          name: "about",
          label: "About",
          
        },
         {
          name: "services",
          label: "Services",
          items: [
            { name: "design", label: "Design",  },
            { name: "develop", label: "Develop",   },
            { name: "support", label: "Support",  },
            
            
          ]
        },
         {
           name: "careers",
           label: "Careers",
           
         },
         {
          name: "contact",
          label: "Let's talk",
          
        },
         
       ];
