import React from "react";
import { Link } from "gatsby";
//import { menuItems } from "./menuItems";
const Menu = () => {
  return (
    <>
      <ul className="navbar-nav main-menu d-none d-lg-flex">
        <React.Fragment>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/"
              role="button"
              aria-expanded="false"
            >
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link"
              to="/about"
              role="button"
              aria-expanded="false"
            >
              About
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle gr-toggle-arrow"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              href="#services"
            >
              Services
              <i className="icon icon-small-down"></i>
            </a>
            <ul className="sm-menu-dropdown  dropdown-menu">
              <React.Fragment>
                <li className="drop-menu-item ">
                  <Link to="/design">
                    <div>
                      <b>Design</b>
                    </div>
                  </Link>
                </li>
                <li className="drop-menu-item">
                  <Link to="/develop">
                    <div>
                      <b>Develop</b>
                    </div>
                  </Link>
                </li>
                <li className="drop-menu-item">
                  <Link to="/support">
                    <div>
                      <b>Support</b>
                    </div>
                  </Link>
                </li>
              </React.Fragment>
            </ul>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link ml-3"
              to="/careers"
              role="button"
              aria-expanded="false"
            >
              Careers
            </Link>
          </li>
        </React.Fragment>
      </ul>
    </>
  );
};
export default Menu;
