import React from "react";
import Footer2 from "./Footer2";

const Footer = ({ className = "", style, ...rest }) => {
  let FooterEl = Footer2;

  
  

  return (
    <>
      <FooterEl className={className} {...rest} />
    </>
  );
};

export default Footer;
