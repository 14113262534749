import React from "react";
import { Link } from "gatsby";
import logoF from "../../assets/image/logo-01.png";
import img1 from "../../assets/image/media/icons/icons8-facebook.svg";
import img2 from "../../assets/image/media/icons/icons8-twitter.svg";
import img3 from "../../assets/image/media/icons/icons8-linkedin-2.svg";
const today = new Date();
const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div className={`pt-13 pt-lg-25 pb-13 pb-lg-18 ${className}`} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-lg-3 col-md-3">
              <div className="mb-7 pr-15">
              <img src={logoF} alt="footerImage" className="mb-6" width="100%"/>
                <p className="font-size-5 line-height-28">
                WeWork Galaxy MG Road, 43, Residency Road, Bengaluru - 560025
                hello@cloudstackai.com
                </p>
              </div>
              
            </div>
                <div className="col-md-2 col-xs-6 footer-two">
                  <div className="mb-10 mb-lg-0">
                    
                    <ul className="list-unstyled">
                    <li className="mb-6">
                        <h5>
                          About
                        </h5>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/about"
                          className="font-size-5 text-default-color"
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/careers"
                          className="font-size-5 text-default-color"
                        >
                          Careers
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/contact"
                          className="font-size-5 text-default-color"
                        >
                          Contact
                        </Link>
                      </li>
                      
                      
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-2 col-xs-6 footer-two">
                  <div className="mb-10 mb-lg-0">
                    
                    <ul className="list-unstyled">
                    <li className="mb-6">
                        <h5>
                          Services
                        </h5>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/design"
                          className="font-size-5 text-default-color"
                        >
                          Design
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/develop"
                          className="font-size-5 text-default-color"
                        >
                          Develop
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/support"
                          className="font-size-5 text-default-color"
                        >
                          Support
                        </Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Singlr widget */}
                <div className="col-md-2 col-xs-6 footer-two">
                  <div className="mb-10 mb-lg-0">
                    
                    <ul className="list-unstyled">
                    <li className="mb-6">
                        <h5>
                          Legal
                        </h5>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/privacy"
                          className="font-size-5 text-default-color"
                        >
                          Privacy
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/terms"
                          className="font-size-5 text-default-color"
                        >
                          Terms
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/cookies"
                          className="font-size-5 text-default-color"
                        >
                          Cookie policy
                        </Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Singlr widget */}
                <div className="col-md-3 col-xs-6 footer-two">
                  <div className="mb-10 mb-lg-0">
                    
                    <ul className="list-unstyled">
                    <li className="mb-6">
                        <h5>
                        Connect With Us
                        </h5>
                        <ul className="list-unstyled company-logo">
                          <li><a href="#" ><span><img src={img1} alt="facebook logo" className="w-100"/></span></a></li>
                          <li><a href="#" ><span><img src={img2} alt="twitter logo" className="w-100"/></span></a></li>
                          <li><a href="#" ><span><img src={img3} alt="LinkedIn logo" className="w-100"/></span></a></li>
                        </ul>
                      </li>
                      
                      
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
            
            </div>
            <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-4 text-bali-gray">
                  © {today.getFullYear()} Cloudstack Automation Pvt Ltd. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        
      </div>
      </div>
    </>
  );
};

export default Footer;
